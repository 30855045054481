import React, {Component} from 'react';
import {APP_GLOBAL} from "../App/DataManager";
import {
    Badge,
    BreadcrumbNavigation,
    ContentTileHeaded,
    Format,
    SequencialFadeIn,
    Toggle
} from '@beyonityeu/beyonity-ui-buttons';
import {CATEGORY_ALL, CATEGORY_LIVING, FILTERABLE_CATEGORIES} from "../FilterBox";
import styles from './index.module.scss';




class UsageChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayedCategory: props.filterSettings.category,
            onlyAvailable    : false,
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filterSettings.category !== this.props.filterSettings.category) {
            this.setState({displayedCategory: this.props.filterSettings.category});
        }
    }


    renderChartBar = (free, total, formatTitle, formatSubTitle, category, type, categoryTotal, typeObject) => {
        let totalPercent = ( total / categoryTotal ) * 100;
        let freePercent = 100;
        let area = total;
        let badge = typeObject.numberOfUnits.total;

        if (this.state.onlyAvailable) {
            freePercent = ( free / total ) * 100;
            area = free;
            badge = typeObject.numberOfUnits.free;
        }

        return (
            <div className={styles.category} key={`${type}`}>
                <div className={styles.dataArea}>
                    <div className={styles.name + ` beyonity-ui--text__medium-highlighted color-text--gray-800`}>
                        {formatTitle(type)}
                    </div>
                    <div className={styles.name + ` beyonity-ui--text__small color-text--gray-700`}>
                        {formatSubTitle(area)}
                    </div>
                </div>
                <div className={styles.bar}>
                    <div className={styles.barFull} style={{width: `${totalPercent}%`}}>
                        <div className={styles.barFree} style={{width: `${freePercent}%`}}/>
                    </div>
                </div>
                <div className={styles.badge}>
                    <Badge size={"s"} className={styles.elevatedBadge}
                           backgroundColor={"var(--color-background--gray-0)"}>
                        <span
                            className={"beyonity-ui--text__medium-highlighted beyonity-ui--line-height__small color-text--gray-800"}>
                            {badge}
                        </span>
                    </Badge>
                </div>
            </div>
        );
    }

    renderChart = () => {
        const {displayedCategory} = this.state;
        const {item} = this.props;

        if (displayedCategory === CATEGORY_ALL) {
            return Object.keys(item.units).map((category) => {
                return Object.keys(item.units[category].types).map((typeName) => {
                    const {free, total} = item.units[category].types[typeName].area;

                    return this.renderChartBar(
                        free,
                        total,
                        (t) => t,
                        Format.formatArea,
                        category,
                        typeName,
                        item.areaTotal,
                        item.units[category].types[typeName]
                    );
                });
            });
        }
        if (displayedCategory === CATEGORY_LIVING) {
            return Object.keys(item.units[displayedCategory].rooms).map((roomType) => {
                const {free, total} = item.units[displayedCategory].rooms[roomType].area;

                return this.renderChartBar(
                    free,
                    total,
                    Format.formatRooms,
                    Format.formatUnits,
                    displayedCategory,
                    roomType,
                    item.units[displayedCategory].totalUnits,
                    item.units[displayedCategory].rooms[roomType]
                );
            });
        }

        return Object.keys(item.units[displayedCategory].types).map((typeName) => {
            const {free, total} = item.units[displayedCategory].types[typeName].area;
            return this.renderChartBar(
                free,
                total,
                (t) => t,
                Format.formatArea,
                displayedCategory,
                typeName,
                item.units[displayedCategory].totalArea,
                item.units[displayedCategory].types[typeName]
            );
        });
    }


    render() {
        const {displayedCategory, onlyAvailable} = this.state;
        const {item} = this.props;
        const functions = [];
        const {language} = APP_GLOBAL;

        item.availableCategories.forEach((category) => {
            if (!FILTERABLE_CATEGORIES.includes(category)) return;
            functions.push({
                id  : category,
                name: APP_GLOBAL.language.categories[category].name,
            });
        });

        functions.push({
            id  : "all",
            name: APP_GLOBAL.language.categories.all
        });

        let unitsCount;
        if (displayedCategory === CATEGORY_ALL) {
            unitsCount = onlyAvailable ? item.unitsFreeCount : item.unitsCount;
        } else {
            unitsCount = onlyAvailable ? item.units[displayedCategory].freeUnits : item.units[displayedCategory].totalUnits;
        }

        return (
            <ContentTileHeaded title={APP_GLOBAL.language?.details?.usage} className={styles.usage}>
                <div className={styles.usageBody}>
                    <div className={styles.navigiagtion}>
                        <BreadcrumbNavigation size="s" navItems={functions}
                                              pointerPosition={displayedCategory}
                                              onNavChange={(item) => {
                                                  this.setState({displayedCategory: item});
                                              }}/>
                    </div>
                    <div className={styles.header}>
                        <div className={styles.left}>
                            <Toggle size={"xs"} onChange={(e) => {
                                this.setState({onlyAvailable: e});
                            }}/>
                            <span className={"beyonity-ui--text__small color-text--gray-800"}>nur verfügbar</span>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.title + " beyonity-ui--text__heading-1 color-text--gray-900"}>
                                {unitsCount}
                            </div>
                            <div
                                className={styles.name + " beyonity-ui--text__medium-highlighted color-text--gray-800"}>
                                {language.general.measurementUnits.units}
                            </div>
                        </div>
                    </div>
                    <div className={styles.categories}>
                        <SequencialFadeIn>
                            {this.renderChart()}
                        </SequencialFadeIn>
                    </div>
                </div>
            </ContentTileHeaded>
        );
    }
}

export default UsageChart;