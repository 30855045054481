import React from 'react';
import ContentBox from './../ContentBox';
import {
    AddressSearch,
    BeyBroadcaster,
    BeyonityUiUtils,
    Button,
    ButtonGroup,
    CheckboxLabeled,
    ContentTile,
    Format,
    Icon,
    Label,
    RadioButton,
    RadioButtonGroup,
    RangeSlider,
    Slider,
    Toggle
} from '@beyonityeu/beyonity-ui-buttons';

import styles from './index.module.scss';
import {APP_GLOBAL, DISPLAY_MODES} from "../App/DataManager";
import BoxArea from "../BoxArea/BoxArea";




export const CATEGORY_COMMERCE = "commerce";
export const CATEGORY_LIVING = "living";
export const CATEGORY_INFO_SPOT = "infoFlag";
export const CATEGORY_ALL = "all";

export const ITEM_TYPE_INFO_SPOT = "infoFlag";
export const ITEM_TYPE_NAVIGATOR = "Navigator";

export const FILTERABLE_CATEGORIES = [CATEGORY_COMMERCE, CATEGORY_LIVING];
export const FILTER_SETTINGS_CHANGE_EVENT_TOPIC = "fs_change"

export const HIGHLIGHT_SHAPES_EVENT_TOPIC = "sh_highlight";
export const FILTER_BOX_TOGGLE_EVENT_TOPIC = "fb_toggle";

class FilterBox extends ContentBox {

    addressSearchRef = React.createRef();
    debounceTime = 250;
    debounceTimeout = null;


    constructor(props) {
        super(props);
        this.state = {
            filterSettings: APP_GLOBAL.defaultFilterSettings,
            minFreeValue: 0,
            maxFreeValue: 0,
            ...this.state
        };

        BeyBroadcaster.instance.subscribe(FILTER_BOX_TOGGLE_EVENT_TOPIC, () => {
            if (this.isOpen()) this.close();
            else this.open();
        });

        BeyBroadcaster.instance.subscribe(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, (topic, event) => {
            if (JSON.stringify(this.state.filterSettings) !== JSON.stringify(event)) {
                this.setState({filterSettings: event});
            }
        });
    }


    componentDidMount() {
        super.componentDidMount()
        this._loadFromSessionStorage();
        this._loadFromSessionStorage();
        this._calculateAreaMinMax();
        this.onFilterSettingsChange();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.filterSettings.category !== prevState.filterSettings.category
            || !BeyonityUiUtils.compareObjects(this.state.filterSettings.type, prevState.filterSettings.type)) {
            this._calculateAreaMinMax();
        }
    }


    componentWillUnmount() {
        super.componentWillUnmount();
    }


    open(delay) {
        super.open(delay);
        return;
        this.setState({open: true}, () => {
            setTimeout(() => {
                window.addEventListener("click", this._detectOutsideClick);
            }, delay);
        });
    }


    close() {
        super.close();
        window.removeEventListener("click", this._detectOutsideClick);
    }


    render() {
        if (!this.state.filterSettings) return null;
        super.render()
    }


    renderHeader() {
        super.renderHeader();
        return APP_GLOBAL.language.filterSort.title;
    }


    renderBody = () => {
        super.renderBody();
        console.log("renderBody");
        return (
            <>
                {this._renderSort()}
                {this._renderLocationFilter()}
                {this._renderCategoryAndTypeFilter()}
                {this._renderCategorySpecificFilter()}
                {/*this._renderPropertyFilters()*/}
                {this._renderInfoFlagFilter()}
            </>
        )
    }


    renderFooter = () => {
        super.renderFooter();
        const {filterSort} = APP_GLOBAL.language;
        return (
            <ButtonGroup>
                <Button label={filterSort.reset}
                        centered
                        fullWidth
                        buttonType={"borderless"}
                        buttonVariant={"destructive"}
                        size="l"
                        onClick={this._reset}/>
                <Button label={`${filterSort.submit}`}
                        fullWidth
                        centered
                        notBreaking
                        size="l"
                        onClick={this.close.bind(this)}
                        buttonVariant={"main"}
                        buttonType={"primary"}/>
            </ButtonGroup>
        )
    }

    _renderInfoFlagFilter = () => {
        const {filterSettings} = this.state;
        if (!filterSettings?.infoFlagCategory) return null;
        const allChecked = ( filterSettings.infoFlagCategory?.includes("all") );
        return (
            <BoxArea title={"Points of Interest"}>
                <div className={styles.filterBox}>
                    <div className={styles.orderToggle}>
                        <Toggle size={"xs"} checked={allChecked} onChange={(e) => {
                            let filterSettings = BeyonityUiUtils.copyObject(this.state.filterSettings);
                            if (!allChecked) {
                                filterSettings.infoFlagCategory.push(`all`);
                            } else {
                                filterSettings.infoFlagCategory = [];
                            }
                            this.setState({filterSettings});
                            this.onFilterSettingsChange();
                        }}>
                        </Toggle>
                        <span className={"beyonity-ui--text__small color-text--gray-800"}>Alle auswählen</span>
                    </div>

                    <div className={styles.typeFilters}>
                        {
                            <div className={styles.typeFilters}>
                                {
                                    APP_GLOBAL.data.availableInfoFlagCategories.map((infoFlagCategory) => {
                                        let enabled = filterSettings.infoFlagCategory.includes(infoFlagCategory) || filterSettings.infoFlagCategory.includes("all");
                                        return <Button label={APP_GLOBAL.data.infoFlagCategories[infoFlagCategory].name}
                                                       className={enabled && "active-filter-btn-text"}
                                                       iconLeft={APP_GLOBAL.data.infoFlagCategories[infoFlagCategory].icon}
                                                       buttonVariant={enabled ? "custom" : "main"}
                                                       key={infoFlagCategory}
                                                       size={"s"}
                                                       buttonType={enabled ? "primary" : ""}
                                                       customVariantColors={{
                                                           primary  : enabled ? "255, 255 ,255" : APP_GLOBAL.data.infoFlagCategories[infoFlagCategory].colors,
                                                           secondary: APP_GLOBAL.data.infoFlagCategories[infoFlagCategory].color
                                                       }
                                                       }
                                                       onClick={() => {
                                                           let newFilterSettings = BeyonityUiUtils.copyObject(filterSettings);
                                                           let enabled = newFilterSettings.infoFlagCategory.includes(infoFlagCategory);
                                                           if (!enabled) {
                                                               newFilterSettings.infoFlagCategory.push(infoFlagCategory);
                                                           } else {
                                                               newFilterSettings.infoFlagCategory = newFilterSettings.infoFlagCategory.filter((t) => t !== infoFlagCategory);
                                                               newFilterSettings.infoFlagCategory = newFilterSettings.infoFlagCategory.filter((t) => t !== `all`);
                                                           }
                                                           this.setState({filterSettings: newFilterSettings}, () => {
                                                               BeyBroadcaster.instance.publish(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, BeyonityUiUtils.copyObject(newFilterSettings));
                                                           });
                                                       }}/>
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </BoxArea>
        )
    }


    //
    // --------- Rendering Filters -----------
    //



    _renderCategoryAndTypeFilter = () => {
        const {filterSettings} = this.state;
        const {filterSort, categories} = APP_GLOBAL.language;
        const allChecked = ( filterSettings.type.includes("all") );
        const {availableCategories, availableTypesPerCategory} = APP_GLOBAL.data;

        console.log("availableCategories", availableCategories);
        let result = (
            ( ( !availableCategories || availableCategories.length === 0 ) &&
                ( Object.keys(availableTypesPerCategory).length === 0 ) ) ? null :
            <BoxArea className={styles.filterBox}
                     title={filterSort.filter.type.title}>
                <div className={styles.typeFilter}>
                    {( availableCategories.length >= 2 ) &&
                        <RadioButtonGroup fullWidth>
                            {availableCategories.map((category) => {
                            if (!FILTERABLE_CATEGORIES.includes(category)) return;
                            return <RadioButton name="category"
                                                key={category}
                                                pill
                                                size={"s"}
                                                onChange={() => {
                                                    this.setCategoryFilter(category);
                                                }}
                                                checked={this.state.filterSettings.category === category}
                                                label={categories[category] ? categories[category].name : category}/>
                        })}
                        </RadioButtonGroup>}

                    <div className={styles.orderToggle}>
                        <Toggle size={"xs"} checked={allChecked} onChange={(e) => {
                            let filterSettings = BeyonityUiUtils.copyObject(this.state.filterSettings);
                            if (!allChecked) {
                                filterSettings.type.push(`all`);
                            } else {
                                filterSettings.type = [];
                            }
                            this.setState({filterSettings});
                            this.onFilterSettingsChange();
                        }}>
                        </Toggle>
                        <span className={"beyonity-ui--text__small color-text--gray-800"}>Alle auswählen</span>
                    </div>

                    {this.state.filterSettings.category !== "all" &&
                        <div className={styles.typeFilters}>
                            {APP_GLOBAL.data.availableTypesPerCategory[this.state.filterSettings.category] &&
                                APP_GLOBAL.data.availableTypesPerCategory[this.state.filterSettings.category].map((type) => {
                                    if (type === "all") return;

                                    if (allChecked && !this.state.filterSettings.type.includes(type)) {
                                        this.changedTypeFilterBecauseOfAll = true;
                                        filterSettings.type.push(type);
                                    }
                                    let enabled = this.state.filterSettings.type.includes(type) || allChecked;
                                    return <Button label={type}
                                                   key={type}
                                                   buttonVariant={"main"}
                                                   size={"s"}
                                                   buttonType={enabled ? "secondary" : "default"}
                                                   onClick={() => {
                                                       let filterSettings = BeyonityUiUtils.copyObject(this.state.filterSettings);
                                                       let enabled = filterSettings.type.includes(type);
                                                       if (!enabled) {
                                                           filterSettings.type.push(type);
                                                       } else {
                                                           filterSettings.type = filterSettings.type.filter((t) => t !== type);
                                                           filterSettings.type = filterSettings.type.filter((t) => t !== `all`);
                                                       }
                                                       this.setState({filterSettings});
                                                       this.onFilterSettingsChange();
                                                   }}></Button>
                                })
                            }
                        </div>
                    }
                </div>
            </BoxArea>

        );

        if (this.changedTypeFilterBecauseOfAll) {
            this.onFilterSettingsChange();
            this.changedTypeFilterBecauseOfAll = false;
        }
        return result;
    }


    _detectOutsideClick = (event) => {
        return;
        if (this.innerBoxRef.current && !this.innerBoxRef.current.contains(event.target)) {
            this.close();
        }
    }


    _renderSort() {
        const {filterSettings} = this.state;
        const {filterSort} = APP_GLOBAL.language;
        let iconStyle = filterSettings.sort.asc ? {transform: "rotate(180deg)"} : {transform: "rotate(0deg)"};
        return (
            <BoxArea className={styles.filterBox}
                     elevation={"E000"} title={filterSort.sort.title}>
                <div className={styles.sortFilter}>
                    <RadioButtonGroup>
                        <RadioButton name="sortBy"
                                     label={filterSort.sort.dsc}
                                     checked={filterSettings.sort.by === "dsc"}
                                     onChange={() => {
                                         let filterSettings = BeyonityUiUtils.copyObject(this.state.filterSettings);
                                         filterSettings.sort = {
                                             by : "dsc",
                                             asc: true
                                         };
                                         this.setState({filterSettings});
                                         this.onFilterSettingsChange();
                                     }}
                        />
                        <RadioButton name="sortBy"
                                     label={filterSort.sort.areaFree}
                                     checked={filterSettings.sort.by === "areaFreeTotal"}
                                     onChange={() => {
                                         let filterSettings = BeyonityUiUtils.copyObject(this.state.filterSettings);
                                         filterSettings.sort = {
                                             by : "areaFreeTotal",
                                             asc: false
                                         };
                                         this.setState({filterSettings});
                                         this.onFilterSettingsChange();
                                     }}/>
                    </RadioButtonGroup>
                    <div className={styles.orderToggle}>
                        <Toggle size={"xs"} checked={filterSettings.sort.asc} onChange={(e) => {
                            let filterSettings = BeyonityUiUtils.copyObject(this.state.filterSettings);
                            filterSettings.sort.asc = !filterSettings.sort.asc;
                            this.setState({filterSettings});
                            this.onFilterSettingsChange();
                        }}></Toggle>
                        <span className={"beyonity-ui--text__small color-text--gray-800"}>Absteigend</span>
                    </div>
                </div>
            </BoxArea>
        );
    }


    _renderLocationFilter() {

        if (APP_GLOBAL.displayMode === DISPLAY_MODES.canvas) return null;
        const {filterSettings} = this.state;
        const {filterSort} = APP_GLOBAL.language;

        return (
            <BoxArea title={filterSort.filter.map.title} elevation={"E000"}>
                {/*This one is reversed with flex to prevent the address suggestions laying beneath the slider*/}
                <div className={`${styles.reverse} ${styles.filterBox}`}>
                    <Slider minValue={1}
                            maxValue={50}
                            steps={1}
                            value={filterSettings.location.radius}
                            disabled={!this.state.filterSettings.location.lat}
                            onChange={(radius) => {
                                let filterSettings = BeyonityUiUtils.copyObject(this.state.filterSettings);
                                filterSettings.location.radius = radius;
                                this.setState({filterSettings}, () => {
                                    this.onFilterSettingsChange();
                                });
                            }}
                    />
                    {this.renderFilterHeader("radar", filterSort.filter.map.circumcircle, filterSettings.location.radius + filterSort.filter.map.circumcircleUnit)}
                    <AddressSearch ref={this.addressSearchRef}
                                   apiKey={`AIzaSyBd1GCAdcqLPIvQKcuU4qmEyI388DgbUm0`}
                                   mapLocalization={"DE-de"}
                                   onSelect={(event) => {
                                       let filterSettings = BeyonityUiUtils.copyObject(this.state.filterSettings);
                                       if (event) {
                                           filterSettings.location.lat = event.geometry.location.lat();
                                           filterSettings.location.lng = event.geometry.location.lng();
                                           filterSettings.location.name = event.address_components[0].short_name;
                                       } else {
                                           filterSettings.location.lat = false;
                                           filterSettings.location.lng = false;
                                           filterSettings.location.name = false;
                                       }
                                       this.setState({filterSettings}, () => {
                                           this.onFilterSettingsChange();
                                       });
                                   }}/>
                </div>
            </BoxArea>
        )

    }


    _renderCategorySpecificFilter() {
        const {filterSettings, minFreeValue, maxFreeValue} = this.state,
            {categorySpecificProperties} = filterSettings,
            {filterSort} = APP_GLOBAL.language;
        switch (this.state.filterSettings.category) {
            case "living":
                break;
            case "commerce":
                const {commerce} = categorySpecificProperties;
                if (minFreeValue === 0 && maxFreeValue === 0) return null;
                if (minFreeValue === undefined || maxFreeValue === undefined) return null;
                if (minFreeValue === null || maxFreeValue === null) return null;
                return (
                    <BoxArea title={filterSort.filter.area.available.title}>
                        <div className={styles.filterBox}>
                            {this.renderFilterHeader("crop_square", "Fläche", Format.formatArea(commerce.area.from) +
                                " - " + Format.formatArea(commerce.area.to))}
                            <RangeSlider minValue={minFreeValue}
                                         maxValue={maxFreeValue}
                                         minDefault={minFreeValue}
                                         maxDefault={maxFreeValue}
                                         minRange={10}
                                         onRangeChange={(min, max) => {
                                             let tmpFilterSettings = BeyonityUiUtils.copyObject(filterSettings);
                                             tmpFilterSettings.categorySpecificProperties.commerce.area.from = +min;
                                             tmpFilterSettings.categorySpecificProperties.commerce.area.to = +max;
                                             this.setState({filterSettings: tmpFilterSettings}, () => {
                                                 this.onFilterSettingsChange();
                                             });
                                         }}/>
                        </div>
                    </BoxArea>
                )
                break;
        }
    }


    _renderPropertyFilters = () => {

        if (!APP_GLOBAL.data.filterConfiguration || !APP_GLOBAL.data.filterConfiguration.generalPropertyFilters) return null;
        const propertyFilters = APP_GLOBAL.data.filterConfiguration.generalPropertyFilters;

        return (
            <ContentTile className={styles.filterBox}
                         elevation={"E000"}>
                <div className={styles.header}>
                    <div className={styles.headline}>{"Eigenschaften"}</div>
                    <Toggle size={"xs"}
                            checked={this.state.filterSettings.propertyFilters}
                            onChange={(checked) => {
                                let filterSettings = BeyonityUiUtils.copyObject(this.state.filterSettings);
                                if (checked) filterSettings.propertyFilters = {}
                                else filterSettings.propertyFilters = false;
                                this.setState({filterSettings});
                                this.onFilterSettingsChange();
                            }}/>
                </div>
                {
                    propertyFilters.map((propertyFilter) => {
                        switch (propertyFilter.type) {
                            case "range":
                                return this._renderRangePropertyFilter(propertyFilter);
                            case "number":
                                return this._renderNumberFilter(propertyFilter);
                            case "boolean":
                                return this._renderBooleanFilter(propertyFilter);
                            case "options":
                                return this._renderOptions(propertyFilter);
                            default:
                                return null;
                        }
                    })}

            </ContentTile>
        );
    }


    //
    // --------- Helpers -----------
    //

    onFilterSettingsChange() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            this._storeInSessionStorage();
            BeyBroadcaster.instance.publish(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, BeyonityUiUtils.copyObject(this.state.filterSettings));
        }, this.debounceTime);
    }


    setCategoryFilter = (category) => {
        let filterSettings = BeyonityUiUtils.copyObject(this.state.filterSettings);
        filterSettings.category = category;
        filterSettings.type = BeyonityUiUtils.copyObject(APP_GLOBAL.data.availableTypesPerCategory[category]);
        this.setState({filterSettings: filterSettings}, this.onFilterSettingsChange);
    }

    _reset = () => {
        let defaultFilterSettings = BeyonityUiUtils.copyObject(APP_GLOBAL.defaultFilterSettings);
        this.setState({filterSettings: defaultFilterSettings});
        this.addressSearchRef.current && this.addressSearchRef.current.clear();
        BeyBroadcaster.instance.publish(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, BeyonityUiUtils.copyObject(defaultFilterSettings));
    }


    //---- Others ----//

    renderFilterHeader(iconName, title, values) {
        return (
            <div className={styles.filterInfo}>
                <div className={styles.left}>
                    <Icon size={"s"}
                          color={`var(--color-text--gray-600)`}
                          name={iconName}/>
                    <span>{title}</span>
                </div>
                <div className={styles.right}>
                    {values}
                </div>
            </div>
        );
    }


    _renderRangePropertyFilter(propertyFilter) {
        return undefined;
    }


    _renderBooleanFilter(propertyFilter) {
        const {filterSettings} = this.state,
            {propertyFilters} = filterSettings;
        return <>
            {
                this.renderFilterHeader(propertyFilter.icon, propertyFilter.label,
                    propertyFilters[propertyFilter.property] ? propertyFilters[propertyFilter.property].max : propertyFilter.max)}

            <div className={styles.switchPropertyFilter}>
                <Label label={propertyFilter.label}>
                    <Toggle disabled={!propertyFilters}
                            checked={propertyFilters[propertyFilter.property]}
                            onChange={(checked) => {
                                if (checked) {
                                    filterSettings.propertyFilters[propertyFilter.property] = {
                                        value: true,
                                        type: propertyFilter.type
                                    };
                                } else {
                                    filterSettings.propertyFilters[propertyFilter.property] = false;
                                }
                                this.setState({filterSettings: filterSettings});
                                this.onFilterSettingsChange();
                            }}
                            size={"xs"}/>
                </Label>
            </div>
        </>
    }


    _renderNumberFilter(propertyFilter) {

        const {filterSettings} = this.state,
            {propertyFilters} = filterSettings;
        return <>
            {
                this.renderFilterHeader(propertyFilter.icon, propertyFilter.label,
                    propertyFilters[propertyFilter.property] ? propertyFilters[propertyFilter.property].max : propertyFilter.max)}
            <Slider minValue={0}
                    maxValue={propertyFilter.max}
                    disabled={!propertyFilters}
                    default={propertyFilter.max}
                    onChange={
                        (value) => {
                            filterSettings.propertyFilters[propertyFilter.property] = {
                                max: value,
                                type: propertyFilter.type
                            };
                            this.setState({filterSettings: filterSettings});
                            this.onFilterSettingsChange();
                        }
                    }
            />
        </>
    }


    _renderOptions(propertyFilter) {
        const {filterSettings} = this.state,
            {propertyFilters} = filterSettings;
        const options = propertyFilter.options;
        let optionsString = "";
        if (filterSettings.propertyFilters[propertyFilter.property] && filterSettings.propertyFilters[propertyFilter.property].options) {
            filterSettings.propertyFilters[propertyFilter.property].options.forEach((option, index) => {
                if (index === 0) optionsString += option;
                else optionsString += `, ${option}`

            })
        }

        return <>
            {
                this.renderFilterHeader(propertyFilter.icon, propertyFilter.label, optionsString)}
            <RadioButtonGroup stacked>
                {
                    options.map((option) => {
                        return <CheckboxLabeled
                            key={propertyFilter.label}
                            label={option}
                            disabled={!propertyFilters}
                            checked={propertyFilters[propertyFilter.property] ? propertyFilters[propertyFilter.property].options.includes(option) : true}
                            onChange={(event) => {
                                if (propertyFilters[propertyFilter.property] === undefined) propertyFilters[propertyFilter.property] = {};
                                if (propertyFilters[propertyFilter.property].options === undefined) propertyFilters[propertyFilter.property].options = [];
                                propertyFilters[propertyFilter.property].type = propertyFilter.type;
                                if (event.target.checked) {
                                    if (!propertyFilters[propertyFilter.property].options.includes(option)) propertyFilters[propertyFilter.property].options.push(option)
                                } else {
                                    propertyFilters[propertyFilter.property].options = propertyFilters[propertyFilter.property].options.filter((t) => t !== option);
                                }
                                this.setState({filterSettings: filterSettings});
                                this.onFilterSettingsChange();
                            }}/>
                    })
                }
            </RadioButtonGroup>
        </>
    }


    _storeInSessionStorage() {
        sessionStorage.setItem("filterSettings" + APP_GLOBAL.data.dsc, JSON.stringify(this.state.filterSettings));
    }


    _loadFromSessionStorage() {
        let filterSettings = JSON.parse(sessionStorage.getItem("filterSettings" + APP_GLOBAL.data.dsc));
        if (filterSettings) {
            this.setState({filterSettings});
        }
    }


    _calculateAreaMinMax() {

        const minValue = () => {
            const {category, type} = this.state.filterSettings;
            const {areaFreeMinMaxByType} = APP_GLOBAL.data;


            let min = null;

            this.state.filterSettings.type.forEach((type) => {
                    try {
                        if (min === null) min = areaFreeMinMaxByType[category][type].min;
                        else if (areaFreeMinMaxByType[category][type].min < min) {
                            min = areaFreeMinMaxByType[category][type].min;
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
            );
            return min;
        }

        const maxValue = () => {
            const {category, type} = this.state.filterSettings;
            const {areaFreeMinMaxByType} = APP_GLOBAL.data;

            let max = null;
            type.forEach((type) => {
                try {
                    if (max === null) max = areaFreeMinMaxByType[category][type].max;
                    else if (areaFreeMinMaxByType[category][type].max > max) {
                        max = areaFreeMinMaxByType[category][type].max;
                    }
                }
                catch (e) {
                    console.error(e);
                }
            });
            return max;
        }


        let maxFreeValue = maxValue();
        let minFreeValue = minValue();
        this.setState({minFreeValue: minFreeValue, maxFreeValue: maxFreeValue});

        // adjust state if current value is out of range
        let filterSettings = BeyonityUiUtils.copyObject(this.state.filterSettings);

        let changed = false;
        let newMin = filterSettings.categorySpecificProperties.commerce.area.from;
        let newMax = filterSettings.categorySpecificProperties.commerce.area.to;

        // temporary fix both will always be changed
        const bothZero = ( newMin === 0 && newMax === 0 );
        if (newMax < minFreeValue || newMax === undefined || isNaN(newMin) || bothZero || true) {
            newMin = minFreeValue;
            changed = true;
        }
        if (newMax > maxFreeValue || newMax === undefined || isNaN(newMax) || bothZero || true) {
            newMax = maxFreeValue;
            changed = true;
        }

        if (changed) {
            this.setState({
                filterSettings: {
                    ...filterSettings,
                    categorySpecificProperties: {
                        ...filterSettings.categorySpecificProperties,
                        commerce: {
                            ...filterSettings.categorySpecificProperties.commerce,
                            area: {
                                from: newMin,
                                to  : newMax
                            }
                        }
                    }
                }
            })
        }



    }
}

export default FilterBox;
